import { useSelector } from "react-redux";

import CurrentStatusDescription from "./CurrentStatusDescription";
import NextStepButton from "./NextStepButton";

export default function WelcomeCard({
  onPersonalIdentity,
  onCompleteRegistration,
  children,
}) {
  const { sellerApplicationStatus, firstName, kycVerificationStatus } =
    useSelector((state) => state.auth.data);

  if (firstName) {
    return (
      <div className="w-full max-w-[888px] mx-auto flex flex-col gap-4 text-[#454745] text-sm leading-[21.7px] font-sans p-4">
        <div className="mt-4">
          <h2 className="bricks-content-title">Welcome, {firstName}!</h2>
          <div className="bricks-content-txt">
            <CurrentStatusDescription status={sellerApplicationStatus} />
          </div>

          <NextStepButton
            status={sellerApplicationStatus}
            kycVerificationStatus={kycVerificationStatus}
            onPersonalIdentity={onPersonalIdentity}
            onCompleteRegistration={onCompleteRegistration}
          />

          {sellerApplicationStatus &&
            sellerApplicationStatus.toLowerCase() === "approved" &&
            children}
        </div>
      </div>
    );
  }

  return null;
}
