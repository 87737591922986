/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_KYC_LINK } from "../../../../queries/user/queries";
import WelcomeCard from "./WelcomeCard";
import WizeCard from "./WizeCard";
import { useNavigate } from "react-router-dom";

const HomeSeller = ({
  firstName,
  kycVerificationStatus,
  sellerApplicationStatus,
}) => {
  const [error, setError] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (sellerApplicationStatus === null) {
      navigate("/settings/company-profile/business-details", { replace: true });
    }
  }, [sellerApplicationStatus, navigate]);

  const [startKycVerification, { loading }] = useLazyQuery(GET_KYC_LINK, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: ({ startKycVerification: { link } }) => {
      window.location.href = link;
    },
    onError: (error) => {
      setError(JSON.stringify(error));
    },
  });

  const onPersonalIdentity = useCallback(() => {
    startKycVerification();
  }, []);

  const onCompleteRegistration = useCallback(() => {
    navigate("/settings/company-profile/business-details");
  }, [navigate]);

  return (
    <>
      {sellerApplicationStatus &&
        sellerApplicationStatus.toLowerCase() !== "approved" && (
          <WelcomeCard
            onCompleteRegistration={onCompleteRegistration}
            onPersonalIdentity={onPersonalIdentity}
          />
        )}
      {sellerApplicationStatus &&
        sellerApplicationStatus.toLowerCase() === "approved" && <WizeCard />}
    </>
  );
};

export default HomeSeller;
