/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import types from "../../../../redux/types";
import Preloader from "../../../Base/Preloader";

const SignOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useApolloClient();

  // State to track if an error occurs
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Clear Apollo cache
      client.clearStore().then(() => {
        // Dispatch actions after cache is cleared
        dispatch({
          type: types.SIGN_OUT_START,
        });

        dispatch({
          type: types.SIGN_OUT_SUCCESS,
        });

        // Navigate to signin page instead of home
        navigate("/auth/signin");
      });
    } catch (error) {
      // Handle any errors here
      setError("Error:", error);
      // Also redirect to signin on error
      navigate("/auth/signin");
    }
  }, [dispatch, navigate, client]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Preloader />
    </>
  );
};

export default React.memo(SignOut);
