import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import types from "../redux/types";

const INACTIVE_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

export const useAutoLogout = (isAuthenticated) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client = useApolloClient();
  const timeoutRef = useRef(null);

  const logout = async () => {
    try {
      // Clear Apollo cache first
      await client.clearStore();

      // Dispatch logout success directly to clear the auth state
      dispatch({
        type: types.SIGN_OUT_SUCCESS,
      });

      // Navigate to login
      navigate("/auth/signin");
    } catch (error) {
      console.error("Auto-logout error:", error);
      // Force navigation to login even if there's an error
      navigate("/auth/signin");
    }
  };

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (isAuthenticated) {
      timeoutRef.current = setTimeout(logout, INACTIVE_TIMEOUT);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      return;
    }

    // Setup event listeners for user activity
    const events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    // Reset timer on any user activity
    const handleActivity = () => {
      resetTimer();
    };

    // Add event listeners
    events.forEach((event) => {
      document.addEventListener(event, handleActivity);
    });

    // Initial timer setup
    resetTimer();

    // Cleanup
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      events.forEach((event) => {
        document.removeEventListener(event, handleActivity);
      });
    };
  }, [isAuthenticated]); // Only re-run effect when auth state changes
};
