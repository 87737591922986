import React from "react";
import { useSelector } from "react-redux";
import HomeSeller from "./HomeSeller";
import HomeUnderwriter from "./HomeUnderwriter";

const HomeContainer = () => {
  const { firstName, role, kycVerificationStatus, sellerApplicationStatus } =
    useSelector((state) => state.auth.data);

  return (
    <Home
      sellerApplicationStatus={sellerApplicationStatus}
      firstName={firstName}
      role={role}
      kycVerificationStatus={kycVerificationStatus}
    />
  );
};

const Home = ({
  sellerApplicationStatus,
  firstName,
  role,
  kycVerificationStatus,
}) => {
  return role != "SELLER" ? (
    <HomeUnderwriter firstName={firstName} />
  ) : (
    <HomeSeller
      sellerApplicationStatus={sellerApplicationStatus}
      firstName={firstName}
      kycVerificationStatus={kycVerificationStatus}
    />
  );
};

export default React.memo(HomeContainer);
